<template>
  <div class="characters w-100">
    <div class="d-flex align-items-center">
      <div class="title">{{ sectionTitle }}</div>
    </div>

    <Grid :lg="3" :md="2" :sm="2" :scrollSm="false">
      <CharacterCard
        :openBlank="openBlank"
        v-for="character of characters"
        :key="character.id"
        class="grid-item"
        :character="character"
        :char-comments-count="get(charCommentsCounts, `${get(character, 'id')}.comments_count`) || 0"
      />
    </Grid>

    <div class="d-flex align-items-center mt-3">
      <back-to-top v-show="get(characters, 'length')" class="d-flex align-self-start" />
      <router-link v-if="seeAllLink" :to="seeAllLink" @click="goToCharacters" class="ml-auto see-all-text show-mob"
        ><b>See all</b></router-link
      >
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import { Action } from 's-vuex-class';
import { Prop, Watch } from 'vue-property-decorator';
import constants from '@/shared/statics/constants';
import namespace from '@/shared/store/namespace';
import { Character } from '@/shared/types/static-types';
import Reaction from '@/shared/components/Reaction/index.vue';
import CharacterCardSmall from '@/shared/components/CharacterCardSmall.vue';
import BackToTop from '@/shared/components/BackToTop.vue';
import { getCommentsCount } from '@/shared/actions/comments';
import Grid from '@/shared/components/storage/Grid.vue';
import CharacterCard from '@/shared/components/storage/CharacterCard.vue';
import { truncateText } from '@/shared/utils/string';

@Options({
  name: 'HomeVerticalCharacters',
  components: { Reaction, CharacterCardSmall, BackToTop, Grid, CharacterCard },
})
export default class HomeVerticalCharacters extends Vue {
  get = get;
  truncateText = truncateText;

  @Prop({ default: () => 'Characters' }) public sectionTitle!: string;
  @Prop({ required: true }) public characters!: Character[];
  @Prop({ required: false }) public seeAllLink!: {};
  @Prop({ required: false }) public search!: {};
  @Prop({ required: false }) public openBlank!: boolean;

  @Action('react', { namespace: namespace.CharactersModule })
  public react!: any;

  public goToCharacters() {
    this.$emit('switchTab');
  }

  public areCommentCountsLoading = false;
  public charCommentsCounts = {};

  public async reactionChanged(reaction: string, character: any) {
    await this.react({ reaction, character });
  }

  public async fetchCharsCommentCounts() {
    this.areCommentCountsLoading = true;
    this.charCommentsCounts = {};
    const resp = await getCommentsCount(map(this.characters, 'id'), constants.commentedAsTypes.CHARACTER);
    this.charCommentsCounts = keyBy(get(resp, 'counts', []), 'commented_on_object_id');
    this.areCommentCountsLoading = false;
  }

  @Watch('characters', { immediate: true })
  charactersChanged() {
    if (get(this.characters, 'length')) {
      this.fetchCharsCommentCounts();
    }
  }

  public gotoCharProfile(character: Character) {
    const router = useRouter();
    router.push({ name: 'character-profile-new', params: { slug: character.slug } });
  }

  public goToCharaters() {
    this.$emit('switchTab');
  }

  public onReaction(characterIndex: number, reaction: any) {
    if (this.search) {
      this.characters[characterIndex] = {
        ...this.characters[characterIndex],
        ...reaction.updatedReactionsData,
      };
    }
  }
}
</script>
<style lang="sass" scoped>
.title
  font-size: 24px
  font-weight: bold
  margin-bottom: 12px
  margin-top: 30px

.see-all-text
  margin-top: 1.5rem
  color: #214163
</style>
