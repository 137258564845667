<template>
  <div class="blabs w-100">
    <div class="d-flex align-items-center">
      <div class="title">{{ sectionTitle }}</div>
    </div>

    <Grid :lg="2" :md="2" :sm="2" :scrollSm="false">
      <blab-card
        class="grid-item"
        :replies-count="get(blabRepliesCount, `${post.id}.blab_replies_count`) || 0"
        v-for="(post, index) of blabs"
        :key="post.id"
        :post="post"
        @open="openBlabModal"
        :openBlank="openBlank"
        @afterReaction="(reactionResp) => onBlabReaction(index, reactionResp)"
    /></Grid>

    <div class="d-flex align-items-center mt-3">
      <back-to-top v-show="get(blabs, 'length')" class="d-flex align-self-start" />
      <router-link @click="goToBlabs" v-if="seeAllLink" :to="seeAllLink" class="ml-auto see-all-text show-mob"
        ><b>See all</b></router-link
      >
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import BackToTop from '@/shared/components/BackToTop.vue';
import { Prop, Watch } from 'vue-property-decorator';
import BlabCard from '@/shared/components/BlabCardsmall.vue';
import BlabSunshineCard from '@/shared/components/BlabSunShineCardVertical.vue';
import { Blab, BlabReply } from '@/shared/types/static-types';
import { getCountofBlabReplies, getCountofReplies, getSunShineBlabReply } from '@/shared/actions/blabs';
import BlabDetailsModal from '@/shared/modals/BlabDetailsModal.vue';
import { authStore } from '@/shared/pinia-store/auth';
import Grid from '@/shared/components/storage/Grid.vue';
import { isMobileFn } from '@/apps/mobile/native/device';

@Options({
  name: 'BlabsVertical',
  components: { BlabCard, BlabDetailsModal, BlabSunshineCard, Grid, BackToTop },
})
export default class BlabsVertical extends Vue {
  @Prop({ default: () => 'Blabs' }) public sectionTitle!: string;
  @Prop({ required: true }) public blabs!: Blab[];
  @Prop({ required: false }) public seeAllLink!: {};
  @Prop({ required: false }) public openBlank!: boolean;

  get = get;

  public get isAuthenticated() {
    const { isAuthenticated } = authStore();
    return isAuthenticated.value;
  }

  isEdit = false;
  openBlabAsModal = false;
  openprofile = false;
  public blabRepliesCount = {};
  sunShineReply: any = {};
  sunshinerepliesCounts = {};
  public blabSunShineReply: any = [];
  blabid = '';

  @Watch('blabs')
  blabsChanged() {
    this.fetchBlabReplies();
    this.fetchSunshineReply();
  }

  public async openBlabModal(id: string) {
    const router = useRouter();
    this.blabid = id;

    const route = { name: 'post-details', params: { id } };

    const isMobile = await isMobileFn();

    if (this.openBlank && !isMobile) {
      const { href } = router.resolve(route);

      window.open(href, '_blank');
      return;
    }

    router.push({ name: 'post-details', params: { id } });
  }

  public onBlabReaction(blabIndex: number, reaction: any) {
    this.blabs[blabIndex] = {
      ...this.blabs[blabIndex],
      ...reaction.updatedReactionsData,
    };
  }

  public openProfile(openprofile: boolean) {
    this.blabid = '';
    this.openprofile = openprofile;
    if (this.openprofile) {
      this.openBlabAsModal = false;
    }
  }

  public async fetchSunshineReply() {
    this.sunShineReply = {};
    const resp = await getSunShineBlabReply(map(this.blabs, 'id'));
    this.sunshinerepliesCounts = {};
    const respreply = await getCountofReplies(map(resp, 'reply.id'));
    this.sunshinerepliesCounts = keyBy(respreply, 'parent_reply_id');
    this.sunShineReply = groupBy(resp, 'blab');
  }

  public closeBlabAsModal(val?: any) {
    this.openBlabAsModal = false;
    this.$nextTick(() => (this.blabid = ''));
    if (val.detail.role === 'backdrop') {
      this.closeBlabModalRoute();
    }
  }

  public closeBlabModalRoute() {
    const router = useRouter();
    router.push({ name: 'home' });
  }

  public openEditBlab(edit: boolean, id: string) {
    const router = useRouter();
    this.isEdit = edit;
    if (this.isEdit) {
      this.blabid = '';
      this.openBlabAsModal = false;
      router.push({ name: 'edit-post', params: { id } });
    }
  }

  public goToBlabs() {
    this.$emit('switchTab');
  }

  public openDetailsPage() {
    this.openBlabAsModal = false;
  }

  public async fetchBlabReplies() {
    this.blabRepliesCount = {};
    const resp = await getCountofBlabReplies(map(this.blabs, 'id'));
    this.blabRepliesCount = keyBy(resp, 'blab');
  }

  public onReaction(id: string, event: { reactResp: {}; firstpost: BlabReply }) {
    this.blabSunShineReply = get(this.sunShineReply, `${id}`);
    const index = this.blabSunShineReply.findIndex(
      (replyfield: { reply: { id: string } }) => replyfield.reply.id === event.firstpost.id
    );
    this.blabSunShineReply[index].reply = {
      ...event.firstpost,
      ...get(event.reactResp, 'updatedReactionsData', {}),
    };
  }
}
</script>
<style lang="sass" scoped>
.title
  font-size: 24px
  font-weight: bold
.see-all-text
  color: #214163
</style>
