<template>
  <div>
    <div v-if="get(listings, 'length')" class="row">
      <div
        v-for="(listing, index) in listings"
        :key="index"
        class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch"
      >
        <router-link :to="{ name: 'mp-listing-details', params: { slug: listing.slug } }">
          <ion-card class="listing-card mx-0 mb-3">
            <div class="h-100">
              <div class="one-listing">
                <div class="d-flex justify-content-center">
                  <router-link :to="{ name: 'mp-listing-details', params: { slug: listing.slug } }">
                    <img
                      loading="lazy"
                      v-image
                      :src="resizeUpload(listing.visuals[0], '190h')"
                      class="clickable-item-hov"
                    />
                  </router-link>
                </div>

                <div class="px-2 pt-2 pb-3">
                  <div class="name d-flex justify-content-center text-center clickable-item-hov mb-3">
                    {{ listing.title }}
                  </div>

                  <ion-badge
                    v-if="isAuthenticated && user.id === listing.seller.id && !listing.is_active"
                    color="danger"
                    class="mb-2"
                    >Paused</ion-badge
                  >

                  <div class="d-flex justify-content-between">
                    <div class="user">{{ listing.seller.username }}</div>
                    <div class="baseprice">
                      <span v-if="listing.base_price === 0">Free</span> <span v-else>${{ listing.base_price }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ion-card>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { resizeUpload } from '@/shared/utils/upload';
import { Prop } from 'vue-property-decorator';
import { authStore } from '@/shared/pinia-store/auth';

@Options({
  name: ' MarketplaceVertical ',
  components: {},
})
export default class MarketplaceVertical extends Vue {
  get = get;
  resizeUpload = resizeUpload;
  @Prop() listings: any;

  public get isAuthenticated() {
    const { isAuthenticated } = authStore();
    return isAuthenticated.value;
  }

  public get user() {
    const { user } = authStore();
    return user.value;
  }
}
</script>
<style lang="sass" scoped>
.router-link
  color: #214163
.marketplace-card-small
  img
    width: 60px
    height: 60px
    border-radius: 40px
    border: solid gray 0.1px
    object-fit: cover
  .name
    font-size: 18px
    font-weight: bold
  .description
    font-size: 13px
  .name
    font-size: 18px
    font-weight: bold
    color: #737373
    cursor: pointer
  .name:hover
    opacity: 0.7

.new-listing-btn
  color: #214163
  font-size: 14px
  font-weight: bold
.title
  font-size: 24px
  font-weight: bold

.one-listing
  img
    height: 130px
    width: 100vh !important
    object-fit: cover

.baseprice
  font-size: 18px
  font-weight: bold

.listing-card
  height: calc(100% - 1.5rem) !important
</style>
