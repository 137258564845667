<template>
  <div class="worlds w-100">
    <div class="d-flex align-items-center">
      <div class="title">{{ sectionTitle }}</div>
    </div>

    <Grid :lg="2" :md="2" :sm="1" :scrollSm="false">
      <SocialSpaceCard
        :openBlank="openBlank"
        class="grid-item"
        v-for="(space, index) of socialSpaces"
        :member-count="get(memberCounts, `${get(space, 'id')}.member_count`) || 0"
        :room-count="get(roomCounts, `${get(space, 'id')}.room_count`) || 0"
        :key="index"
        :index="index"
        :socialSpace="space"
      />
    </Grid>

    <div class="d-flex align-items-center mt-3">
      <back-to-top class="d-flex align-self-start" />
      <router-link v-if="seeAllLink" :to="seeAllLink" class="ml-auto see-all-text" @click="goToSpaces"
        ><b>See all</b></router-link
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import Grid from '@/shared/components/storage/Grid.vue';
import SocialSpaceCard from '@/shared/components/storage/SocialSpaceCard.vue';
import { SocialSpace } from '@/shared/types/static-types';
import { getSocialSpaceRoomsCount, getWorldsMemberCounts } from '@/shared/actions/worlds';
import BackToTop from '@/shared/components/BackToTop.vue';
import VerticalSocialSpaceCard from '@/shared/components/VerticalSocialSpaceCard.vue';
import { getchatUnreadCounts } from '@/shared/actions/socialSpaceChatroom';
import { authStore } from '@/shared/pinia-store/auth';
const { isAuthenticated } = authStore();
const props = defineProps({
  socialSpaces: {
    type: Array<any>,
    default: () => [],
    required: true,
  },
  sectionTitle: {
    type: String,
    default: 'Social Space',
  },

  isSearch: {
    type: Boolean,
    default: false,
  },
  seeAllLink: {
    type: String,
    default: '',
  },

  openBlank: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['switchTab']);
const areMemberCountsLoading = ref(false);
const memberCounts = ref({});
const roomCounts = ref({});
const chatUnreadCounts = ref({});
const isSearch = toRef(props, 'isSearch');
const socialSpaces = toRef(props, 'socialSpaces');

const fetchchatUnreadCounts = async () => {
  chatUnreadCounts.value = {};
  const resp = await getchatUnreadCounts(map(socialSpaces.value, 'id') as string[]);

  chatUnreadCounts.value = keyBy(resp, 'room');
};
const fetchSocialSpacesMemberCounts = async () => {
  memberCounts.value = {};
  const resp = await getWorldsMemberCounts(map(socialSpaces.value, 'id') as string[]);
  memberCounts.value = keyBy(resp, 'world_id');
};
const fetchSocialSpaceRoomCounts = async () => {
  roomCounts.value = {};
  const resp = await getSocialSpaceRoomsCount(map(socialSpaces.value, 'id') as string[]);
  roomCounts.value = keyBy(resp, 'world_id');
};

watch(
  socialSpaces,
  () => {
    if (!isEmpty(socialSpaces.value) && isAuthenticated.value) {
      fetchSocialSpacesMemberCounts();
      fetchSocialSpaceRoomCounts();
      fetchchatUnreadCounts();
    }
  },
  { immediate: true, deep: true }
);

const goToSpaces = () => {
  if (isSearch.value) {
    emits('switchTab');
  }
};

const goToWorlds = () => {
  emits('switchTab');
};

const onReaction = (event: { reactResp: {}; social: SocialSpace }) => {
  const index = socialSpaces.value.findIndex((space: any) => space.id === event.social.id);
  socialSpaces.value[index] = { ...event.social, ...get(event.reactResp, 'updatedReactionsData', {}) };
};
</script>
<style lang="sass" scoped>
.title
  font-size: 24px
  font-weight: bold
  margin-bottom: 12px
.see-all-text
  color: #214163
  display: inline-block
</style>
