<template>
  <div class="search-card-small d-flex align-items-center">
    <img
      loading="lazy"
      v-if="tab === 'tags'"
      class="clickable"
      src="/hashtag.png"
      @click="goToHashTagText(search.name)"
    />
    <a v-else-if="tab === 'users'" @click="goToUser(search.username)" class="text">
      <CommenterImage
        class="clickable"
        :src="resizeUpload(get(search, 'profile_picture_url', '/empty.png'), '80x80')"
        :ring="get(search, 'customize_profile.profile_ring.image')"
        :ringWidth="60"
        :top="-5"
        type="user"
      />
    </a>

    <div class="rest-info ml-3 flex-grow-1">
      <div v-if="tab === 'tags'" class="name d-flex align-items-center justify-content-start">
        <div v-if="tab === 'tags'" class="clickable" @click="goToHashTagText(search.name)">#{{ search.name }}</div>
      </div>
      <div v-else-if="tab === 'users'" class="name d-flex align-items-center justify-content-start mt-n3">
        <a
          :class="{
            'text-color-username': usernameColor(search) && !usernameColor(search).startsWith('#'),
            'bold-username': usernameColor(search),
          }"
          :style="
            !usernameColor(search)
              ? ''
              : usernameColor(search).startsWith('#')
              ? `color: ${usernameColor(search)} !important`
              : `background-image: ${usernameColor(search)} !important`
          "
          @click="goToUser(search.username)"
          class="clickable text-black mr-1"
        >
          @{{ search.username }}
        </a>
        <ProfileBadge :badges="get(search?.customize_profile, 'badges') || []" :show-small-badge="true" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { authStore } from '@/shared/pinia-store/auth';
import CommenterImage from '@/shared/components/storage/CommenterImage.vue';
import ProfileBadge from '@/shared/pages/profile/components/ProfileBadge.vue';
import { resizeUpload } from '@/shared/utils/upload';
import { isMobileFn } from '@/apps/mobile/native/device';

@Options({
  name: 'SearchCard',
  components: { CommenterImage, ProfileBadge },
})
export default class SearchCard extends Vue {
  @Prop({}) search!: any;
  @Prop({}) tab!: any;
  @Prop({}) openBlank!: any;

  public get isAuthenticated() {
    const { isAuthenticated } = authStore();
    return isAuthenticated.value;
  }

  get = get;
  resizeUpload = resizeUpload;

  public goToHashTagText(name: any) {
    this.$emit('switchTab', name);
  }

  public usernameColor = (user: any) => {
    return user?.customize_profile?.username_color || '';
  };

  public async goToUser(username: string) {
    const router = useRouter();

    const route = {
      name: 'profile',
      params: { username },
    };

    const isMobile = await isMobileFn();

    if (this.openBlank && !isMobile) {
      const { href } = router.resolve(route);
      window.open(href, '_blank');
      return;
    }

    router.push(route);
  }
}
</script>

<style lang="sass" scoped>
.search-card-small
  img
    width: 60px
    height: 60px
    border-radius: 40px
    object-fit: cover
  .name
    font-size: 18px
    font-weight: bold
  .description
    font-size: 13px
</style>
