<template>
  <div class="worlds w-100">
    <div class="d-flex align-items-center">
      <div class="title">{{ sectionTitle }}</div>
    </div>

    <Grid class="mt-2" :scrollSm="false" :lg="2" :md="2" :sm="1">
      <StoryWorldCard
        :vertical="isDesktopSize"
        :openBlank="openBlank"
        v-for="world of worlds"
        :key="world.id"
        class="grid-item"
        :world="world"
      />
    </Grid>

    <div class="d-flex align-items-center mt-3">
      <back-to-top class="d-flex align-self-start" />
      <router-link v-if="seeAllLink" :to="seeAllLink" class="ml-auto see-all-text"><b>See all</b></router-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import BackToTop from '@/shared/components/BackToTop.vue';
import Grid from '@/shared/components/storage/Grid.vue';
import StoryWorldCard from '@/shared/components/storage/StoryWorldCard.vue';

const { isDesktopSize, isLessThan570px } = useWindowSize();

defineProps({
  sectionTitle: {
    type: String,
  },
  worlds: {
    type: Array,
  },
  seeAllLink: {},
  search: {},
  openBlank: {
    type: Boolean,
    default: false,
  },
});
</script>
<style lang="sass" scoped>
.title
  font-size: 24px
  font-weight: bold
.see-all-text
  color: #214163
  display: inline-block
</style>
