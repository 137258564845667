<template>
  <ion-segment
    scrollable
    mode="ios"
    color="primary"
    class="tabs compact no-shadow bg-transparent"
    :value="activeIndex"
    @ion-change="segmentChanged($event)"
  >
    <ion-segment-button v-for="(tab, index) of tabs" :key="index" class="shrink bg-transparent" :value="index">
      <ion-label class="d-flex">{{ tab.name }} </ion-label>
    </ion-segment-button>
  </ion-segment>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Tab } from '@/shared/types/static-types';

@Options({
  name: 'SearchTabs',
  components: {},
})
export default class SearchTabs extends Vue {
  @Prop({ type: Array }) public tabs!: Tab[];
  @Prop({ type: Number, default: 0 }) public activeIndex!: number;

  get = get;
  startsWith = startsWith;

  public get windowWidth() {
    return window.innerWidth;
  }

  public segmentChanged($event: CustomEvent) {
    const tabIndex = $event.detail.value as number;
    const tab = this.tabs[tabIndex];

    this.$emit('update:activeIndex', tabIndex);
    this.$emit('tab-changed', tab);
  }

  public get isMobSmallScreen() {
    return this.windowWidth <= 570;
  }
}
</script>

<style scoped lang="sass">
.shrink
  flex: none !important
  height: 30px !important
  min-height: 30px !important

.icon
  font-size: 16px
  font-weight: normal
.svg-icon
  background-color: transparent !important
  fill: transparent !important
.tabs
  overflow-y: hidden

@media (max-width: 570px)
  .tabs
    height: 3rem
</style>
