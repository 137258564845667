<template>
  <div>
    <div v-if="currentTab === 'characters'">
      <div v-if="characters && characters.length">
        <Grid :lg="3" :md="2" :sm="2" :scrollSm="false">
          <CharacterCard
            :openBlank="true"
            v-for="character of characters"
            :key="character.id"
            class="grid-item"
            :character="character"
            :char-comments-count="get(charCommentsCounts, `${get(character, 'id')}.comments_count`) || 0"
          />
        </Grid>
        <div class="d-flex justify-content-center">
          <ion-button v-if="nextPageExists" class="clickable" @click="requestLoadMoreCharacters">Load More</ion-button>
        </div>
      </div>

      <div v-else class="no-data">No results found</div>
    </div>
    <div v-if="currentTab === 'worlds'">
      <div v-if="worlds && worlds.length">
        <Grid :lg="2" :md="2" :sm="1" :scrollSm="false">
          <StoryWorldCard :openBlank="true" class="grid-item" v-for="world of worlds" :key="world.id" :world="world" />
        </Grid>
        <div class="d-flex justify-content-center">
          <ion-button v-if="nextWorldPageExists" class="clickable" @click="requestLoadMoreWorlds">Load More</ion-button>
        </div>
      </div>

      <div v-else class="no-data">No results found</div>
    </div>
    <div v-if="currentTab === 'spaces'">
      <div v-if="!isEmpty(spaces)">
        <Grid :lg="2" :md="2" :sm="1" :scrollSm="false">
          <SocialSpaceCard
            class="grid-item"
            v-for="(space, index) of spaces"
            :social-space="space"
            :key="index"
            :index="index"
            :member-count="get(memberCounts, `${get(space, 'id')}.member_count`) || 0"
            :room-count="get(roomCounts, `${get(space, 'id')}.room_count`) || 0"
            :openBlank="true"
          />
        </Grid>
        <div class="d-flex justify-content-center">
          <ion-button v-if="nextSpacesPageExists" class="clickable" @click="requestLoadMoreSpaces"
            >Load More</ion-button
          >
        </div>
      </div>

      <div v-else class="no-data">No results found</div>
    </div>
    <div v-if="currentTab === 'chatrooms'">
      <div v-if="chatrooms && chatrooms.length">
        <ChatroomCard
          v-for="room in chatrooms"
          :openBlank="true"
          :key="room.id"
          :chatroom="room"
          :member-count="get(counts, `${room.id}.member-count`) || 0"
          :status="get(counts, `${room.id}.status`) || false"
          :lastmsg="get(counts, `${room.id}.last_msg_time`) || null"
        />
        <div class="d-flex justify-content-center">
          <ion-button v-if="nextChatroomsPageExists" class="clickable" @click="requestLoadMoreChatrooms"
            >Load More</ion-button
          >
        </div>
      </div>

      <div v-else class="no-data">No results found</div>
    </div>
    <div v-if="currentTab === 'blabs'">
      <div v-if="blabs && blabs.length">
        <Grid :lg="2" :md="2" :sm="2" :scrollSm="false">
          <blab-card
            class="grid-item"
            v-for="(list, index) of blabs"
            :openBlank="true"
            :key="list.id"
            :replies-count="get(blabRepliesCount, `${list.id}.blab_replies_count`) || 0"
            :post="list"
            @open="openBlabModal"
            @afterReaction="(reactionResp) => onBlabReaction(index, reactionResp)"
          />
        </Grid>

        <div class="d-flex justify-content-center">
          <ion-button v-if="nextBlabPageExists" class="clickable" @click="requestLoadMoreBlabs">Load More</ion-button>
        </div>
      </div>
      <div v-else class="no-data">No results found</div>
    </div>

    <div v-if="currentTab === 'marketplacelistings'">
      <div v-if="mrktplace && mrktplace.length">
        <Grid :lg="3" :md="2" :sm="2" :scrollSm="false">
          <MarketplaceCard
            :openBlank="true"
            class="grid-item"
            v-for="(listing, index) of mrktplace"
            :listing="listing"
            :key="index"
          />
        </Grid>

        <div class="d-flex justify-content-center">
          <ion-button v-if="nextMarketPlacePageExists" class="clickable" @click="requestLoadMoreMarketplace"
            >Load More</ion-button
          >
        </div>
      </div>
      <div v-else class="no-data">No results found</div>
    </div>

    <div v-if="currentTab === 'users'">
      <div v-if="users && users.length">
        <user-tag-small-card
          :openBlank="true"
          v-for="user of users"
          :key="user.id"
          :search="user"
          tab="users"
          class="my-3"
        />
        <ion-button v-if="nextUserPageExists" class="clickable" @click="requestLoadMoreUsers">Load More</ion-button>
      </div>
      <div v-else class="no-data">No results found</div>
    </div>

    <div v-if="currentTab === 'tags'">
      <div v-if="tags && tags.length">
        <user-tag-small-card
          v-for="tag of tags"
          :key="tag.id"
          :search="tag"
          tab="tags"
          class="my-3"
          @switch-tab="searchByTag"
        />
        <div class="d-flex justify-content-center">
          <ion-button v-if="nextTagPageExists" class="clickable" @click="requestLoadMoreTags">Load More</ion-button>
        </div>
      </div>
      <div v-else class="no-data">No results found</div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import MarketplaceVertical from './MarketplaceVerticalCard.vue';
import constants from '@/shared/statics/constants';
import WorldCardSmall from '@/shared/components/WorldCardSmall.vue';
import UserTagSmallCard from '@/shared/components/UserTagSmallCard.vue';
import CharacterCardSmall from '@/shared/components/CharacterCardSmall.vue';
import { getCommentsCount } from '@/shared/actions/comments';
import { getSocialSpaceRoomsCount, getWorldsMemberCounts } from '@/shared/actions/worlds';
import { getCountofBlabReplies, getCountofReplies, getSunShineBlabReply } from '@/shared/actions/blabs';
import { BlabReply, SocialSpace } from '@/shared/types/static-types';
import ChatroomCard from '@/shared/components/storage/ChatroomCard.vue';
import BlabCard from '@/shared/components/BlabCardsmall.vue';
import BlabSunshineCard from '@/shared/components/BlabSunShineCardVertical.vue';
import VerticalSocialSpaceCard from '@/shared/components/VerticalSocialSpaceCard.vue';
import Grid from '@/shared/components/storage/Grid.vue';
import CharacterCard from '@/shared/components/storage/CharacterCard.vue';
import StoryWorldCard from '@/shared/components/storage/StoryWorldCard.vue';
import SocialSpaceCard from '@/shared/components/storage/SocialSpaceCard.vue';
import MarketplaceCard from '@/shared/components/storage/MarketplaceCard.vue';
import { getChatroomCounts } from '@/shared/actions/socialSpaceChatroom';

@Options({
  name: 'InfiniteSearch',
  components: {
    Grid,
    CharacterCard,
    VerticalSocialSpaceCard,
    ChatroomCard,
    UserTagSmallCard,
    BlabCard,
    CharacterCardSmall,
    MarketplaceVertical,
    BlabSunshineCard,
    WorldCardSmall,
    StoryWorldCard,
    SocialSpaceCard,
    MarketplaceCard,
  },
})
export default class InfiniteSearch extends Vue {
  @Prop() public currentTab!: string;
  @Prop({ default: () => {} }) characters!: any;
  @Prop({ default: () => {} }) blabs!: any;
  @Prop({ default: () => {} }) worlds!: any;
  @Prop({ default: () => {} }) mrktplace!: any;
  @Prop({ default: () => {} }) users!: any;
  @Prop({ default: () => {} }) tags!: any;
  @Prop({ default: () => {} }) spaces!: any;
  @Prop({ default: () => {} }) chatrooms!: any;
  @Prop() public paging!: any;
  @Prop() public wrldpaging!: any;
  @Prop() public blabpaging!: any;
  @Prop() public mrktpaging!: any;
  @Prop() public tagpaging!: any;
  @Prop() public userpaging!: any;
  @Prop() public spacespaging!: any;
  @Prop() public chatroomspaging!: any;

  @Watch('blabs')
  getBlabReply() {
    this.fetchBlabReplies();
    this.fetchSunshineReply();
  }

  @Watch('chatrooms')
  getChatroomsCount() {
    this.fetchChatroomCountsAndStatus();
  }

  public async fetchSocialSpacesMemberCounts() {
    this.memberCounts = {};
    const resp = await getWorldsMemberCounts(map(this.spaces, 'id') as string[]);
    this.memberCounts = keyBy(resp, 'world_id');
  }

  public async fetchSocialSpaceRoomCounts() {
    const resp = await getSocialSpaceRoomsCount(map(this.spaces, 'id') as string[]);
    this.roomCounts = keyBy(resp, 'world_id');
  }

  @Watch('spaces', { immediate: true })
  getSpacesCount() {
    this.fetchSocialSpacesMemberCounts();
    this.fetchSocialSpaceRoomCounts();
  }

  public blabRepliesCount = {};
  public roomCounts = {};
  public counts = {};
  public memberSpacesCounts = {};
  public text = '';
  public blabid = '';
  sunShineReply: any = {};
  sunshinerepliesCounts = {};
  public blabSunShineReply: any = [];

  public charCommentsCounts = {};
  public get nextPageExists() {
    return !!this.paging?.next;
  }

  public get nextWorldPageExists() {
    return !!this.wrldpaging?.next;
  }

  public get nextSpacesPageExists() {
    return !!this.spacespaging?.next;
  }

  public get nextChatroomsPageExists() {
    return !!this.chatroomspaging?.next;
  }

  public get nextBlabPageExists() {
    return !!this.blabpaging?.next;
  }

  public get nextMarketPlacePageExists() {
    return !!this.mrktpaging?.next;
  }

  public get nextUserPageExists() {
    return !!this.userpaging?.next;
  }

  public get nextTagPageExists() {
    return !!this.tagpaging?.next;
  }

  public async fetchBlabReplies() {
    this.blabRepliesCount = {};
    const resp = await getCountofBlabReplies(map(this.blabs, 'id'));
    this.blabRepliesCount = keyBy(resp, 'blab');
  }

  public async fetchChatroomCountsAndStatus() {
    const resp = await getChatroomCounts(map(this.chatrooms, 'id') as string[]);
    this.counts = keyBy(resp, 'id');
  }

  public searchByTag(name: any) {
    this.text = name;
    this.$emit('searchTag', { value: 'tags' }, this.text);
  }

  public requestLoadMoreCharacters() {
    this.$emit('load');
  }

  public requestLoadMoreWorlds() {
    this.$emit('loadWorlds');
  }

  public requestLoadMoreSpaces() {
    this.$emit('loadSpaces');
  }

  public requestLoadMoreChatrooms() {
    this.$emit('loadChatrooms');
  }

  public requestLoadMoreBlabs() {
    this.$emit('loadBlabs');
  }

  public requestLoadMoreMarketplace() {
    this.$emit('loadMrktplace');
  }

  public onSunshineShineReaction(id: string, event: { reactResp: {}; firstpost: BlabReply }) {
    this.blabSunShineReply = get(this.sunShineReply, `${id}`);
    const index = this.blabSunShineReply.findIndex(
      (field: { reply: { id: string } }) => field.reply.id === event.firstpost.id
    );
    this.blabSunShineReply[index].reply = {
      ...event.firstpost,
      ...get(event.reactResp, 'updatedReactionsData', {}),
    };
  }

  public onBlabReaction(blabIndex: number, reaction: any) {
    this.blabs[blabIndex] = {
      ...this.blabs[blabIndex],
      ...reaction.updatedReactionsData,
    };
  }

  public openBlabModal(id: string) {
    const router = useRouter();
    this.blabid = id;

    const route = { name: 'post-details', params: { id } };

    const { href } = router.resolve(route);

    window.open(href, '_blank');
  }

  public async fetchSunshineReply() {
    this.sunShineReply = {};
    const resp = await getSunShineBlabReply(map(this.blabs, 'id'));
    this.sunshinerepliesCounts = {};
    const respreply = await getCountofReplies(map(resp, 'reply.id'));
    this.sunshinerepliesCounts = keyBy(respreply, 'parent_reply_id');
    this.sunShineReply = groupBy(resp, 'blab');
  }

  public requestLoadMoreUsers() {
    this.$emit('loadUsers');
  }

  public requestLoadMoreTags() {
    this.$emit('loadTags');
  }

  public memberCounts = {};
  public areCommentCountsLoading = false;
  get = get;
  search = constants.search;

  public async fetchWorldsMemberCounts() {
    this.memberCounts = {};
    const resp = await getWorldsMemberCounts(map(this.worlds, 'id'));
    this.memberCounts = keyBy(resp, 'world_id');
  }

  public onReaction(characterIndex: number, reaction: any) {
    this.characters[characterIndex] = {
      ...this.characters[characterIndex],
      ...reaction.updatedReactionsData,
    };
  }

  public onWorldReaction(worldIndex: number, reaction: any) {
    this.worlds[worldIndex] = {
      ...this.worlds[worldIndex],
      ...reaction.updatedReactionsData,
    };
  }

  public onSpacesReaction(event: { reactResp: {}; social: SocialSpace }) {
    const index = this.spaces.findIndex((social: any) => social.id === event.social.id);
    this.spaces[index] = {
      ...event.social,
      ...get(event.reactResp, 'updatedReactionsData', {}),
    };
  }

  public async fetchCharsCommentCounts() {
    this.areCommentCountsLoading = true;
    this.charCommentsCounts = {};
    const resp = await getCommentsCount(map(this.characters, 'id'), constants.commentedAsTypes.CHARACTER);
    this.charCommentsCounts = keyBy(get(resp, 'counts', []), 'commented_on_object_id');
    this.areCommentCountsLoading = false;
  }

  @Watch('characters', { immediate: true })
  getCharsCommentCounts() {
    this.fetchCharsCommentCounts();
  }
}
</script>

<style scoped lang="sass"></style>
