<template>
  <div>
    <div v-if="characters && characters.length">
      <home-vertical-characters
        :characters="characters"
        :openBlank="true"
        section-title="Characters"
        :search="true"
        :see-all-link="{ name: 'search', query: { ...$route.query, tab: 'characters' }, force: true }"
        @switch-tab="goToCharacters"
      ></home-vertical-characters>
    </div>

    <div v-if="spaces && spaces.length">
      <trending-social-spaces
        :openBlank="true"
        class="mt-5"
        v-show="get(spaces, 'length')"
        :social-spaces="spaces"
        section-title="Social Spaces"
        :see-all-link="{ name: 'search', query: { ...$route.query, tab: 'spaces' }, force: true }"
        :is-search="true"
        @switch-tab="gotoSpaces"
      />
    </div>

    <div v-if="worlds && worlds.length">
      <trending-worlds
        :worlds="worlds"
        section-title="Worlds"
        :search="true"
        :openBlank="true"
        :see-all-link="{ name: 'search', query: { ...$route.query, tab: 'worlds' }, force: true }"
        @switch-tab="goToWorlds"
        class="mt-5"
      ></trending-worlds>
    </div>

    <div v-if="chatrooms && chatrooms.length">
      <home-vertical-chatrooms
        class="mt-5"
        section-title="Chatrooms"
        :to-top="true"
        :is-search="true"
        :openBlank="true"
        :chatrooms="chatrooms"
        :counts="counts"
        :see-all-link="{ name: 'search', query: { ...$route.query, tab: 'chatrooms' }, force: true }"
        @switch-tab="goToChatrooms"
      />
    </div>

    <div v-if="blabs && blabs.length">
      <blabs-vertical
        :openBlank="true"
        class="mt-5"
        :blabs="blabs"
        section-title="Posts"
        :see-all-link="{ name: 'search', query: { ...$route.query, tab: 'blabs' }, force: true }"
        @switch-tab="goToBlabs"
      />
    </div>

    <div v-if="marketplace && marketplace.length">
      <horizontal-marketplace-listing
        toTop
        :openBlank="true"
        class="mt-5"
        :listings="marketplace"
        @switch-tab="goToMarketplaceListings"
      ></horizontal-marketplace-listing>
    </div>

    <div v-if="users && users.length">
      <div class="w-100 mt-5">
        <div class="d-flex align-items-center">
          <div class="title">Users</div>
        </div>

        <div>
          <user-tag-small-card
            :openBlank="true"
            v-for="user of users"
            :key="user.id"
            :search="user"
            tab="users"
            class="my-3"
          />
        </div>
      </div>
      <div class="d-flex align-items-center mt-3">
        <back-to-top class="d-flex align-self-start" />
        <router-link
          :to="{ name: 'search', query: { ...$route.query, tab: 'users' }, force: true }"
          class="ml-auto mr-0 see-all-text"
          @click="goToUsers"
          ><b>See all</b></router-link
        >
      </div>
    </div>

    <div v-if="tags && tags.length">
      <div class="w-100 mt-5">
        <div class="d-flex align-items-center">
          <div class="title">{{ isTagSearch ? 'Similar ' : '' }}Tags</div>
        </div>

        <div>
          <user-tag-small-card
            v-for="tag of tags"
            :key="tag.id"
            :search="tag"
            tab="tags"
            class="my-3"
            @switch-tab="searchByTag"
            :openBlank="true"
          />
        </div>
      </div>
      <div class="d-flex align-items-center mt-3">
        <back-to-top class="d-flex align-self-start" />
        <router-link
          :to="{ name: 'search', query: { ...$route.query, tab: 'tags' }, force: true }"
          class="ml-auto mr-0 see-all-text"
          @click="goToTags"
          ><b>See all</b></router-link
        >
      </div>
    </div>

    <div
      v-if="
        !(characters && characters.length) &&
        !(worlds && worlds.length) &&
        !(blabs && blabs.length) &&
        !(marketplace && marketplace.length) &&
        !(users && users.length) &&
        !(tags && tags.length) &&
        !(spaces && spaces.length) &&
        !(chatrooms && chatrooms.length)
      "
      class="no-data"
    >
      No search results found
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import MarketplaceVertical from './MarketplaceVerticalCard.vue';
import BlabsVertical from './BlabsVertical.vue';
import UserTagSmallCard from '@/shared/components/UserTagSmallCard.vue';
import BackToTop from '@/shared/components/BackToTop.vue';
import HomeVerticalCharacters from '@/shared/pages/home/components/HomeVerticalCharacters.vue';
import TrendingWorlds from '@/shared/pages/home/components/TrendingWorlds.vue';
import HorizontalMarketplaceListing from '@/shared/components/HorizontalMarketplaceListing.vue';
import TrendingSocialSpaces from '@/shared/pages/home/components/TrendingSocialSpaces.vue';
import HomeVerticalChatrooms from '@/shared/pages/social-spaces/components/HomeVerticalChatrooms.vue';
import { getChatroomCounts } from '@/shared/actions/socialSpaceChatroom';
@Options({
  name: 'AllSearchCard',
  components: {
    BackToTop,
    HomeVerticalCharacters,
    TrendingWorlds,
    HomeVerticalChatrooms,
    MarketplaceVertical,
    BlabsVertical,
    UserTagSmallCard,
    HorizontalMarketplaceListing,
    TrendingSocialSpaces,
  },
})
export default class AllSearchCard extends Vue {
  get = get;
  @Prop({}) characters: any;
  @Prop({}) spaces: any;
  @Prop({}) worlds: any;
  @Prop({}) blabs: any;
  @Prop({}) users: any;
  @Prop({}) marketplace: any;
  @Prop({}) tags: any;
  @Prop({}) chatrooms: any;
  @Prop({}) isTagSearch?: boolean;

  public text = '';
  public counts = {};
  @Watch('chatrooms')
  getChatroomsCount() {
    this.fetchChatroomCountsAndStatus();
  }

  public async fetchChatroomCountsAndStatus() {
    const resp = await getChatroomCounts(map(this.chatrooms, 'id') as string[]);
    this.counts = keyBy(resp, 'id');
  }

  public goToCharacters() {
    this.$emit('changeTab', { value: 'characters' });
  }

  public goToUsers() {
    this.$emit('changeTab', { value: 'users' });
  }

  public searchByTag(name: any) {
    this.text = name;
    this.$emit('searchTag', { value: 'tags' }, this.text);
  }

  public goToTags() {
    this.$emit('changeTab', { value: 'tags' });
  }

  public goToBlabs() {
    this.$emit('changeTab', { value: 'blabs' });
  }

  public goToChatrooms() {
    this.$emit('changeTab', { value: 'chatrooms' });
  }

  public gotoSpaces() {
    this.$emit('changeTab', { value: 'spaces' });
  }

  public goToMarketplaceListings() {
    this.$emit('changeTab', { value: 'marketplacelistings' });
  }

  public goToWorlds() {
    this.$emit('changeTab', { value: 'worlds' });
  }
}
</script>
<style lang="sass" scoped>
.title
  font-size: 24px
  font-weight: bold

.see-all-text
  color: #214163
  font-size: 16px
</style>
