<template>
  <div class="wrapper">
    <transition>
      <div v-if="listings && listings.length" class="listings w-100">
        <div class="top-bar d-flex align-items-center">
          <div class="title">{{ sectionTitle }}</div>
        </div>
        <div class="d-flex listing-list h-100 position-relative mt-2">
          <Grid :lg="3" :sm="2">
            <MarketplaceCard
              :openBlank="openBlank"
              v-for="(listing, index) of listings"
              :key="index"
              class="grid-item"
              :listing="listing"
            />
          </Grid>
        </div>
      </div>
      <div v-else class="no-data">No listings</div>
    </transition>

    <div class="d-flex align-items-center mt-3">
      <back-to-top v-if="toTop" class="d-flex align-self-start" />
      <router-link v-if="seeAllLink" :to="seeAllLink" class="ml-auto mr-0 see-all-text" @click="goToMarketPlaceListings"
        ><b>See all</b></router-link
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import BackToTop from '@/shared/components/BackToTop.vue';
import Grid from '@/shared/components/storage/Grid.vue';
import MarketplaceCard from '@/shared/components/storage/MarketplaceCard.vue';

defineProps({
  sectionTitle: {
    type: String,
    default: 'Marketplace',
  },
  listings: {
    type: Array,
  },
  toTop: {
    type: Boolean,
  },
  openBlank: {
    type: Boolean,
    default: false,
  },
  seeAllLink: {
    default: () => ({ name: 'marketplace' }),
  },
});

const emits = defineEmits(['switchTab']);

const goToMarketPlaceListings = () => {
  emits('switchTab');
};
</script>

<style lang="sass" scoped>
.see-all-text
  color: #214163
  display: inline-block
.title
  font-size: 24px
  font-weight: bold
</style>
